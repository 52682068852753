export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "cs": {
        "action": {
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přepnout navigaci"])},
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlásit se"])}
        },
        "analyticsNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomozte zlepšit Central!"])},
        "projectsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efektivně spravujte a dohlížejte na projekty systému."])},
        "usersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ovládejte a spravujte uživatelské účty bezproblémově."])},
        "systemDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zjednodušte konfiguraci, sledování a přístup k auditním záznamům."])}
      },
      "de": {
        "action": {
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation umschalten"])},
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausloggen"])}
        },
        "analyticsNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilf Central zu verbessern!"])},
        "projectsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwalten und überwachen Sie Systemprojekte effizient."])},
        "usersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollieren und verwalten Sie Benutzerkonten nahtlos."])},
        "systemDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimieren Sie Konfiguration, Überwachung und Zugriff auf Audit-Logs."])}
      },
      "es": {
        "action": {
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar la navegación"])},
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])}
        },
        "analyticsNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayuda a mejorar Central"])},
        "projectsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administre y supervise eficientemente los proyectos del sistema."])},
        "usersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controle y administre las cuentas de usuario sin problemas."])},
        "systemDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplifique la configuración, monitoreo y acceso a los registros de auditoría."])}
      },
      "fr": {
        "action": {
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basculer la navigation"])},
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])}
        },
        "analyticsNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aidez à améliorer Central !"])},
        "projectsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérez et supervisez efficacement les projets du système."])},
        "usersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôlez et administrez les comptes utilisateurs de manière fluide."])},
        "systemDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplifiez la configuration, la surveillance et l'accès aux journaux d'audit."])}
      },
      "id": {
        "action": {
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigasi Toggle"])},
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keluar"])}
        },
        "analyticsNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bantu Memperbaiki Central!"])},
        "projectsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelola dan awasi proyek-proyek sistem secara efisien."])},
        "usersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kendalikan dan administrasikan akun pengguna dengan lancar."])},
        "systemDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplifikasi konfigurasi, pemantauan, dan akses ke catatan audit."])}
      },
      "it": {
        "action": {
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva/disattiva navigazione"])},
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnettersi"])}
        },
        "analyticsNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiuta a migliorare Central"])},
        "projectsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestisci e supervisualmente in modo efficiente i progetti del sistema."])},
        "usersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla e amministra gli account utente senza intoppi."])},
        "systemDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semplifica la configurazione, il monitoraggio e l'accesso ai log di audit."])}
      },
      "ja": {
        "action": {
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ナビゲーションを有効化"])},
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログアウト"])}
        },
        "analyticsNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centralの改善を支援！"])},
        "projectsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロジェクトを効率的に管理・監視します。"])},
        "usersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーアカウントをスムーズに制御・管理します。"])},
        "systemDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定、監視、監査ログへのアクセスを簡素化します。"])}
      },
      "sw": {
        "action": {
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geuza urambazaji"])},
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toka kwenye ukurasa"])}
        },
        "analyticsNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saidia kuboresha Central"])},
        "projectsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantera och övervaka systemets projekt effektivt."])},
        "usersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollera och administrera användarkonton smidigt."])},
        "systemDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strömlinjeforma konfiguration, övervakning och åtkomst till revisionsloggar."])}
      }
    }
  })
}
