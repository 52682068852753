<!--
Copyright 2020 ODK Central Developers
See the NOTICE file at the top-level directory of this distribution and at
https://github.com/getodk/central-frontend/blob/master/NOTICE.

This file is part of ODK Central. It is subject to the license terms in
the LICENSE file found in the top-level directory of this distribution and at
https://www.apache.org/licenses/LICENSE-2.0. No part of ODK Central,
including this file, may be copied, modified, propagated, or distributed
except according to the terms contained in the LICENSE file.
-->
<template>
  <li v-if="!loggedIn" id="navbar-actions">
    <a href="#" @click.prevent>
      {{ $t("notLoggedIn") }}
    </a>
  </li>
  <li v-else id="navbar-actions">
    <popover as="div" class="relative">
      <popover-button
        class="flex items-center gap-2 text-sm text-gray-600 focus:outline-none">
        <user-circle-icon class="h-8 w-8" />
      </popover-button>
      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0">
        <popover-panel
          class="absolute w-56 text-gray-700 origin-top-right rounded-md right-0 top-0 mt-8 border shadow bg-white">
          <div class="p-4 border-b">
            <h6 class="text-lg font-semibold truncate">
              {{ currentUser.displayName }}
            </h6>
          </div>
          <ul class="p-1 text-sm">
            <li
              class="w-full rounded-md hover:bg-gray-100 py-2 px-3 cursor-pointer">
              <router-link to="/account/edit" class="block">
                {{ $t("action.editProfile") }}
              </router-link>
            </li>
            <li
              class="w-full rounded-md hover:bg-gray-100 py-2 px-3 cursor-pointer">
              <a
                id="navbar-actions-log-out"
                href="#"
                class="block"
                @click.prevent="logOut">
                {{ $t("action.logOut") }}
              </a>
            </li>
          </ul>
        </popover-panel>
      </transition>
    </popover>
  </li>
</template>

<script>
import { UserCircleIcon } from "@heroicons/vue/24/outline";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/vue";
import { logOut } from "../../util/session";
import { noop } from "../../util/util";
import { useRequestData } from "../../request-data";

export default {
  name: "NavbarActions",
  components: {
    UserCircleIcon,
    Popover,
    PopoverButton,
    PopoverPanel,
    Transition,
  },
  inject: ["container", "alert", "unsavedChanges"],
  props: {
    loggedIn: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // The component does not assume that this data will exist when the
    // component is created.
    const { currentUser } = useRequestData();
    return { currentUser };
  },
  methods: {
    logOut() {
      if (this.unsavedChanges.confirm()) {
        logOut(this.container, false)
          .then(() => {
            this.alert.success(this.$t("alert.logOut"));
          })
          .catch(noop);
      }
    },
  },
};
</script>

<i18n lang="json5">
{
  en: {
    // This text is shown if the user is not logged in. It is shown in the
    // navigation bar at the top of the page.
    notLoggedIn: "Not logged in",
    action: {
      logOut: "Log out",
    },
    alert: {
      logOut: "You have logged out successfully.",
    },
  },
}
</i18n>

<!-- Autogenerated by destructure.js -->
<i18n>
{
  "cs": {
    "notLoggedIn": "Nepřihlášen",
    "action": {
      "logOut": "Odhlásit se"
    },
    "alert": {
      "logOut": "Úspěšně jste se odhlásili."
    }
  },
  "de": {
    "notLoggedIn": "Nicht angemeldet",
    "action": {
      "logOut": "Ausloggen"
    },
    "alert": {
      "logOut": "Sie haben erfolgreich ausgeloggt."
    }
  },
  "es": {
    "notLoggedIn": "No ha iniciado sesión",
    "action": {
      "logOut": "Cerrar sesión"
    },
    "alert": {
      "logOut": "Ha cerrado la sesión correctamente."
    }
  },
  "fr": {
    "notLoggedIn": "Non connecté",
    "action": {
      "logOut": "Se déconnecter"
    },
    "alert": {
      "logOut": "Vous avez été déconnecté avec succès."
    }
  },
  "id": {
    "notLoggedIn": "Belum masuk",
    "action": {
      "logOut": "Keluar"
    },
    "alert": {
      "logOut": "Anda sudah berhasil keluar."
    }
  },
  "it": {
    "notLoggedIn": "Accesso non effettuato",
    "action": {
      "logOut": "Disconnettersi"
    },
    "alert": {
      "logOut": "Ti sei disconnesso con successo."
    }
  },
  "ja": {
    "notLoggedIn": "ログイン",
    "action": {
      "logOut": "ログアウト"
    },
    "alert": {
      "logOut": "ログアウトに成功しました。"
    }
  },
  "sw": {
    "notLoggedIn": "Hujaingia",
    "action": {
      "logOut": "toka kwenye ukurasa"
    },
    "alert": {
      "logOut": "Umetoka kwenye ukurasa kwa mafanikio."
    }
  }
}
</i18n>
