export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "action": {
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle navigation"])},
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log-out"])}
        },
        "analyticsNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Improve SDC!"])},
        "projectsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage and oversee your system's projects."])},
        "usersDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control and administer user accounts seamlessly."])},
        "systemDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streamline configuration, monitoring, and audit log access."])}
      }
    }
  })
}
