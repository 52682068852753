<!--
Copyright 2017 ODK Central Developers
See the NOTICE file at the top-level directory of this distribution and at
https://github.com/getodk/central-frontend/blob/master/NOTICE.

This file is part of ODK Central. It is subject to the license terms in
the LICENSE file found in the top-level directory of this distribution and at
https://www.apache.org/licenses/LICENSE-2.0. No part of ODK Central,
including this file, may be copied, modified, propagated, or distributed
except according to the terms contained in the LICENSE file.
-->
<template>
  <div>
    <nav class="flex items-center justify-between px-8 py-4 text-gray-700 border-b">
      <div class="flex items-center text-center gap-4">
        <popover as="div" class="relative block md:hidden">
          <popover-button class="flex items-center gap-2 text-sm focus:outline-none focus:border-none">
            <span class="sr-only">{{ $t('action.toggle') }}</span>
            <bars3-icon class="h-6 w-6"/>
          </popover-button>
          <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0">
            <popover-panel class="absolute w-80 text-gray-700 text-left origin-top-left rounded-md left-0 top-0 mt-8 border shadow bg-white">
              <div class="p-4 border-b">
                <h6 class="text-lg font-semibold">{{ currentUser.displayName }}</h6>
                <a id="navbar-actions-log-out" href="#" class="text-link text-sm text-gray-600" @click.prevent="logOut">
                  {{ $t('action.logOut') }}
                </a>
              </div>
              <ul class="p-1 mb-4 text-sm">
                <li :class="{ active: projectsLinkIsActive }" class="navigation-link group">
                  <router-link to="/" class="flex items-center gap-4">
                    <div class="rounded-md bg-gray-100 p-2 group-hover:bg-gray-200">
                      <rocket-launch-icon class="h-6 w-6"/>
                    </div>
                    <div>
                      <div class="font-medium">{{ $t('resource.projects') }}</div>
                      <p class="text-gray-500">{{ $t('projectsDesc') }}</p>
                      <span class="sr-only">{{ $t('current') }}</span>
                    </div>
                  </router-link>
                </li>
                <li v-if="canRoute('/users')" id="navbar-links-users" class="navigation-link group"
                  :class="{ active: routePathStartsWith('/users') }">
                  <router-link to="/users" class="flex items-center gap-4">
                    <div class="rounded-md bg-gray-100 p-2 group-hover:bg-gray-200">
                      <user-group-icon class="h-6 w-6"/>
                    </div>
                    <div>
                      <div class="font-medium">{{ $t('resource.users') }}</div>
                      <p class="text-gray-500">{{ $t('usersDesc') }}</p>
                      <span class="sr-only">{{ $t('current') }}</span>
                    </div>
                  </router-link>
                </li>
                <li v-if="canRoute('/system/audits')" class="navigation-link group"
                  :class="{ active: routePathStartsWith('/system') }">
                  <router-link to="/system/audits" class="flex items-center gap-4">
                    <div class="rounded-md bg-gray-100 p-2 group-hover:bg-gray-200">
                      <cog6-tooth-icon class="h-6 w-6"/>
                    </div>
                    <div>
                      <div class="font-medium">{{ $t('common.system') }}</div>
                      <p class="text-gray-500">{{ $t('systemDesc') }}</p>
                      <span class="sr-only">{{ $t('current') }}</span>
                    </div>
                  </router-link>
                </li>
              </ul>
            </popover-panel>
          </transition>
        </popover>
        <router-link to="/" class="text-lg font-bold">Sparksoft Data Collect</router-link>
        <div class="hidden md:block">
          <navbar-links v-if="loggedIn"/>
        </div>
      </div>
      <div class="hidden md:flex items-center justify-end">
        <div class="navbar-right">
          <ul class="nav navbar-nav">
            <navbar-actions :logged-in="loggedIn"/>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { UserGroupIcon, RocketLaunchIcon, Cog6ToothIcon } from '@heroicons/vue/24/outline';
import { Bars3Icon } from '@heroicons/vue/24/solid';
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/vue';
import NavbarActions from './navbar/actions.vue';
import NavbarLinks from './navbar/links.vue';
import { logOut } from '../util/session';
import { noop } from '../util/util';

import modal from '../mixins/modal';
import useRoutes from '../composables/routes';
import { useRequestData } from '../request-data';

export default {
  name: 'Navbar',
  components: {
    Bars3Icon,
    Cog6ToothIcon,
    RocketLaunchIcon,
    UserGroupIcon,
    NavbarActions,
    NavbarLinks,
    Popover,
    PopoverButton,
    PopoverPanel,
    Transition
  },
  mixins: [modal({ analyticsIntroduction: 'AnalyticsIntroduction' })],
  inject: ['config'],
  setup() {
    // The component does not assume that this data will exist when the
    // component is created.
    const { currentUser, analyticsConfig } = useRequestData();
    const { canRoute } = useRoutes();
    return { currentUser, analyticsConfig, canRoute };
  },
  data() {
    return {
      analyticsIntroduction: {
        state: false
      }
    };
  },
  computed: {
    // Usually once the user is logged in (either after their session has been
    // restored or after they have submitted the login form), we render a fuller
    // navbar. However, if after submitting the login form, the user is
    // redirected to outside Frontend, they will remain on /login until they are
    // redirected. In that case, we do not render the fuller navbar.
    loggedIn() {
      return this.currentUser.dataExists && this.$route.path !== '/login';
    },
    projectsLinkIsActive() {
      return this.$route.path === '/' || this.routePathStartsWith('/projects');
    }
  },
  methods: {
    routePathStartsWith(path) {
      return this.$route.path === path ||
        this.$route.path.startsWith(`${path}/`);
    },
    logOut() {
      if (this.unsavedChanges.confirm()) {
        logOut(this.container, false)
          .then(() => {
            this.alert.success(this.$t('alert.logOut'));
          })
          .catch(noop);
      }
    }
  }
};
</script>

<style lang="postcss">
.navigation-link {
  @apply w-full rounded-md hover:bg-gray-100 py-2 px-3 cursor-pointer;
}
</style>

<i18n lang="json5">
{
  "en": {
    "action": {
      // Used by screen readers to describe the button used to show or hide the navigation bar on small screens ("hamburger menu").
      "toggle": "Toggle navigation",
      "logOut": "Log-out"
    },
    "analyticsNotice": "Help Improve SDC!",
    "projectsDesc": "Manage and oversee your system's projects.",
    "usersDesc": "Control and administer user accounts seamlessly.",
    "systemDesc": "Streamline configuration, monitoring, and audit log access."
  }
}
</i18n>

<!-- Autogenerated by destructure.js -->
<i18n>
{
  "cs": {
    "action": {
      "toggle": "Přepnout navigaci",
      "logOut": "Odhlásit se"
    },
    "analyticsNotice": "Pomozte zlepšit Central!",
    "projectsDesc": "Efektivně spravujte a dohlížejte na projekty systému.",
    "usersDesc": "Ovládejte a spravujte uživatelské účty bezproblémově.",
    "systemDesc": "Zjednodušte konfiguraci, sledování a přístup k auditním záznamům."
  },
  "de": {
    "action": {
      "toggle": "Navigation umschalten",
      "logOut": "Ausloggen"
    },
    "analyticsNotice": "Hilf Central zu verbessern!",
    "projectsDesc": "Verwalten und überwachen Sie Systemprojekte effizient.",
    "usersDesc": "Kontrollieren und verwalten Sie Benutzerkonten nahtlos.",
    "systemDesc": "Optimieren Sie Konfiguration, Überwachung und Zugriff auf Audit-Logs."
  },
  "es": {
    "action": {
      "toggle": "Alternar la navegación",
      "logOut": "Cerrar sesión"
    },
    "analyticsNotice": "Ayuda a mejorar Central",
    "projectsDesc": "Administre y supervise eficientemente los proyectos del sistema.",
    "usersDesc": "Controle y administre las cuentas de usuario sin problemas.",
    "systemDesc": "Simplifique la configuración, monitoreo y acceso a los registros de auditoría."
  },
  "fr": {
    "action": {
      "toggle": "Basculer la navigation",
      "logOut": "Se déconnecter"
    },
    "analyticsNotice": "Aidez à améliorer Central !",
    "projectsDesc": "Gérez et supervisez efficacement les projets du système.",
    "usersDesc": "Contrôlez et administrez les comptes utilisateurs de manière fluide.",
    "systemDesc": "Simplifiez la configuration, la surveillance et l'accès aux journaux d'audit."
  },
  "id": {
    "action": {
      "toggle": "Navigasi Toggle",
      "logOut": "Keluar"
    },
    "analyticsNotice": "Bantu Memperbaiki Central!",
    "projectsDesc": "Kelola dan awasi proyek-proyek sistem secara efisien.",
    "usersDesc": "Kendalikan dan administrasikan akun pengguna dengan lancar.",
    "systemDesc": "Simplifikasi konfigurasi, pemantauan, dan akses ke catatan audit."
  },
  "it": {
    "action": {
      "toggle": "Attiva/disattiva navigazione",
      "logOut": "Disconnettersi"
    },
    "analyticsNotice": "Aiuta a migliorare Central",
    "projectsDesc": "Gestisci e supervisualmente in modo efficiente i progetti del sistema.",
    "usersDesc": "Controlla e amministra gli account utente senza intoppi.",
    "systemDesc": "Semplifica la configurazione, il monitoraggio e l'accesso ai log di audit."
  },
  "ja": {
    "action": {
      "toggle": "ナビゲーションを有効化",
      "logOut": "ログアウト"
    },
    "analyticsNotice": "Centralの改善を支援！",
    "projectsDesc": "プロジェクトを効率的に管理・監視します。",
    "usersDesc": "ユーザーアカウントをスムーズに制御・管理します。",
    "systemDesc": "設定、監視、監査ログへのアクセスを簡素化します。"
  },
  "sw": {
    "action": {
      "toggle": "Geuza urambazaji",
      "logOut": "toka kwenye ukurasa"
    },
    "analyticsNotice": "Saidia kuboresha Central",
    "projectsDesc": "Hantera och övervaka systemets projekt effektivt.",
    "usersDesc": "Kontrollera och administrera användarkonton smidigt.",
    "systemDesc": "Strömlinjeforma konfiguration, övervakning och åtkomst till revisionsloggar."
  }
}
</i18n>
