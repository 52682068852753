<!--
Copyright 2017 ODK Central Developers
See the NOTICE file at the top-level directory of this distribution and at
https://github.com/getodk/central-frontend/blob/master/NOTICE.

This file is part of ODK Central. It is subject to the license terms in
the LICENSE file found in the top-level directory of this distribution and at
https://www.apache.org/licenses/LICENSE-2.0. No part of ODK Central,
including this file, may be copied, modified, propagated, or distributed
except according to the terms contained in the LICENSE file.
-->
<template>
  <div v-show="alert.state" :key="alert.at.getTime()"
    class="alert rounded-md ease-in-out text-sm px-4 py-2 font-medium fixed text-center left-2/4 -translate-x-1/2 top-10 w-[512px] z-[100]" :class="status" role="alert">
    <button type="button" class="close mr-2" :aria-label="$t('action.close')"
      @click="alert.blank()">
      <span aria-hidden="true">&times;</span>
    </button>
    <span class="whitespace-pre-wrap break-words">{{ alert.message }}</span>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  inject: ['alert'],
  computed: {
    status() {
      if (this.alert.type === 'success')
        return 'bg-emerald-500 text-white';
      if (this.alert.type === 'danger')
        return 'bg-rose-500 text-white';

      return 'bg-blue-500 text-white';
    }
  }
};
</script>

<!-- Cannot replicate in Tailwind -->
<style lang="scss">
@keyframes fadein {
  from { opacity: 0.2; }
  to { opacity: 1; }
}

.alert {
  animation-duration: 0.6s;
  animation-iteration-count: 1;
  animation-name: fadein;
}
</style>
